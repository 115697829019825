import React from 'react';
import img from "../assets/img/me.jpeg";
import cv from "../assets/pdf/CVCodyMACEDOWeb.pdf";

const Resume = () => {
    return (
        <div className="App-header" id="resume">
            <div className="container">
                <div className="homepage">
                    <div className="logo">
                        <img src={img} alt="logo"/>
                    </div>
                    <div>
                        <h1>CODY MACEDO</h1>
                        <p>
                        Je suis un <span className="bold-text"> développeur full-stack </span> passionné, alliant maîtrise du front-end et expertise du back-end 
                        pour donner vie à des solutions web innovantes et personnalisées, transformant chaque idée en une réalité digitale unique.</p>
                        <h2><a target="_blank" href={cv} className="link-text" rel="noreferrer"> Curriculum Vitae </a></h2>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Resume;
