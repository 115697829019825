import { Route, Switch } from "react-router-dom";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";

function Main() {
    return (
        <Switch>
            <Route path="/contact" component={Contact}>
                <Contact/>
                <Footer/>
            </Route>
            <Route path="/">
                <Header/>
                <Home/>
                <Footer/>
            </Route>
        </Switch>
    )
}

export default Main;