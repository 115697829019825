import React, { useState } from 'react';
import drf from "../../assets/img/drf.png";
import android from '../../assets/svg/android.svg';
import angular from '../../assets/svg/angular.svg';
import css from '../../assets/svg/css.svg';
import django from '../../assets/svg/django.svg';
import hibernate from '../../assets/svg/hibernate.svg';
import html from '../../assets/svg/html.svg';
import java from '../../assets/svg/java.svg';
import javascript from '../../assets/svg/javascript.svg';
import kotlin from '../../assets/svg/kotlin.svg';
import mongodb from '../../assets/svg/mongodb.svg';
import mysql from '../../assets/svg/mysql.svg';
import php from '../../assets/svg/php.svg';
import postgresql from '../../assets/svg/postgresql.svg';
import python from '../../assets/svg/python.svg';
import react from '../../assets/svg/react.svg';
import symfony from '../../assets/svg/symfony.svg';
import Card from './SkillCard';


const Skills = () => {

    const [showMore, setShowMore] = useState(false);


    return (
        <div className="skills" id="skill">
            <div className="container">
                <div className="header-skills">
                    <h1> SKILLS </h1>
                </div>
                <h2>Framework</h2>
                <div className="skills-container">
                    <Card title="Symfony" text="4" img={symfony}/>
                    <Card title="Android" text="5" img={android}/>
                    <Card title="React" text="2" img={react}/>
                    <Card title="NextJS" text="2" img={react}/>
                    <Card title="DRF" text="1" img={drf}/>
                    <Card title="Django" text="1.5" img={django}/>
                    <Card title="Angular" text="2" img={angular}/>
                </div>
                <h2>Langages</h2>
                <div className="skills-container">
                    <Card title="PHP" text="5" img={php}/>
                    <Card title="Python" text="3" img={python}/>
                    <Card title="Java" text="5" img={java}/>
                    <Card title="Kotlin" text="2" img={kotlin}/>
                    <Card title="HTML" text="5" img={html}/>
                    <Card title="CSS" text="5" img={css}/>
                    <Card title="Javascript" text="5" img={javascript}/>
                    <Card title="Hibernate" text="1" img={hibernate}/>
                    <Card title="MySQL" text="5" img={mysql}/>
                    <Card title="PostgreSQL" text="2" img={postgresql}/>
                    <Card title="MongoDB" text="2" img={mongodb}/>
                </div>
            </div>
        </div>
    );
};

export default Skills;
