import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-text">
                    <h3>Cody MACEDO</h3>
                    <p>macedo.cody@gmail.com</p>
                </div>
                <div className="social-network">
                    <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin}/></a>
                    <a href="#"><FontAwesomeIcon icon={faEnvelope}/></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
