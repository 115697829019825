import React from 'react';

const ExperienceCard = ({img, job, mission, date}) => {
    return (
        <div className="experience-card">
            <div className="experience-img">
                <img src={img} alt={"IMG"}/>
            </div>
            <div className="experience-card-content">
                <p><span>Poste // </span> {job}</p>
                <p><span>Mission // </span> {mission}</p>
            </div>
            <div className="experience-card-date">
                {date}
            </div>
        </div>
    );
};

export default ExperienceCard;
