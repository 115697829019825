import './App.scss';
import {
    BrowserRouter as Router,
} from "react-router-dom";


import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {
    return (

        <Router>
            <div>
                <Main/>
            </div>
        </Router>

    );
}

// function Users() {
//     return <h2>Users</h2>;
// }


export default App;
