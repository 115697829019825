import React from 'react';

const Education = () => {
    return (
        <div className="formation" id="formation">
            <div className="container">
                <h1> Education </h1>
                <div className="card-container">
                    <div className="cube">
                        <span>2020/2022</span>
                        <h2>Digital CAMPUS</h2>
                        <span>Paris 11</span>
                        <p>Mastère Teach Lead</p>
                        <p>Développement Web / Algorithmie</p>
                    </div>
                    <div className="cube">
                        <span>2018/2019</span>
                        <h2>IUT PARIS 8</h2>
                        <span>Montreuil</span>
                        <p>Licence Professionnelle (CSID) (Concepteur de Systèmes
                            d’Information, Développeur / JEE/AGILE)</p>
                        <p>OPTION Solutions Logicielles et Applications métiers</p>
                    </div>
                    <div className="cube">
                        <span>2016/2018</span>
                        <h2>Lycée Louis Armand</h2>
                        <span>Nogent-Sur-Marne</span>
                        <p>BTS SIO (Services Informatiques aux Organisations)</p>
                        <p>OPTION Solutions Logicielles et Applications métiers</p>
                    </div>
                    <div className="cube">
                        <span>2013/2015</span>
                        <h2>Lycée Martin Luther King</h2>
                        <span>Bussy-Saint-Georges</span>
                        <p>//Bac STI2D (Sciences et Technologies de
                            l'Industrie et du Développement Durable)</p>
                        <p>//Option SIN</p>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Education;
