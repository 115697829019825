import React from 'react';
import ExperienceCard from "./ExperienceCard";
import kiwip from "../../assets/svg/kiwip.svg";
import netways from "../../assets/img/netways.jpeg";
import consconte from "../../assets/img/consconte.jpg";

const Experience = () => {
    return (

        <div className="experience" id="experience">
            <div className="container">
                <h1> Experience </h1>
                <div className="card-container">
                    <ExperienceCard img={kiwip} job="Développeur Fullstack" mission="Application Mobile (Android, Kotlin), Montre Android, Symfony Api" date="2020-2021" />
                    <ExperienceCard img={netways} job="Développeur Fullstack" mission="Application Mobile Android, Api Platform, Symfony" date="2018-2019" />
                    <ExperienceCard img={consconte} job="Développeur Fullstack" mission="Symfony, MongoDB, Prototype Android" date="2020-2021" />
                </div>
            </div>
        </div>
    );
};

export default Experience;
