import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPaperclip, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import img from "../assets/img/me.jpeg";
import cv from "../assets/pdf/CVCodyMACEDOWeb.pdf";
import mail from '../assets/svg/envelope-regular.svg';
import linkedin from '../assets/svg/linkedin-brands.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Contact = () => {
    return (
        <div className='contact-container'>
            <div className="contact">
                <div className="photo-head">
                    <img src={img} alt="logo" />
                </div>
                <h3> Bonjour, je m'appelle Cody MACEDO. Vous pouvez retrouver tous mes contacts ici !</h3>
                <ul>
                    <li>
                        <a href="mailto:macedo.cody@gmail.com" className="mail"><img src={mail} /> Mail </a>
                    </li>
                    <li>
                        <a className="linkedin" href="https://www.linkedin.com/in/cody-macedo-dev/" target="_blank"
                            rel="noreferrer"> <img alt="linkedin" src={linkedin} /> Linkedin </a>
                    </li>
                    <li>
                        <a className="insta" href="https://www.instagram.com/kydo.7/" target="_blank"
                            rel="noreferrer"> <FontAwesomeIcon icon={faInstagram} /> Instagram </a>
                    </li>

                    <li>
                        <Link className="mywebsite" to="/"
                            rel="noreferrer"> <FontAwesomeIcon icon={faUserAlt} /> Mon Site </Link>
                    </li>

                    <li>
                        <a className="cv" target="_blank" href={cv}
                            rel="noreferrer"> <FontAwesomeIcon icon={faPaperclip} /> Curriculum Vitae </a>
                    </li>
                </ul>

                <h3> Je suis actuellement Développeur Fullstack en web et mobile mais j'aime apprendre de nouvelles technologies, peu importe le domaine.</h3>

                {/* <div className="container">
                <form>
                    <h2> Envoyer moi un message </h2>
                    <label htmlFor="lastname">Nom</label>
                    <input type="text" id="lastname" name="lastname"/>

                    <label htmlFor="firstname">Prénom</label>
                    <input type="text" id="firstname" name="firstname"/>

                    <label htmlFor="firstname">Texte</label>
                    <textarea id="firstname" name="firstname"/>
                    <button type="submit" value="Submit"> Envoyer</button>
                </form>
            </div> */}
            </div>
        </div>
    );
}

export default Contact;
