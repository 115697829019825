import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBrain, faBriefcase, faEnvelope, faGraduationCap, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Tween } from "react-gsap";
import { Link } from "react-router-dom";
import { Controller, Scene } from "react-scrollmagic";


const Header = () => {

    const [isOpen, setIsOpen] = useState(false);


    return (

        <header>
            <Controller>
                <Scene duration={200} triggerElement="" triggerHook={0}>
                    {(progress) => (
                        <Tween
                            from={{
                                css: {
                                    paddingTop: '60',
                                },
                                ease: 'Sine.easeOut',
                            }}
                            to={{
                                css: {
                                    paddingTop: '25',
                                },
                                ease: 'Sine.easeOut',
                            }}
                            totalProgress={progress}
                            paused
                        >
                            <nav>

                                <div className="navbar">

                                    <Link href="/" to="/" className="logo-topbar">Cody MACEDO</Link>
                                    <div className="menu-fullscreen">
                                        <ul>
                                            <li>
                                                <a href="#resume"> <FontAwesomeIcon icon={faUserAlt} /></a>
                                            </li>
                                            <li>
                                                <a href="#skill"> <FontAwesomeIcon icon={faBrain} /></a>
                                            </li>
                                            <li>
                                                <a href="#experience"> <FontAwesomeIcon icon={faBriefcase} /></a>
                                            </li>
                                            <li>
                                                <a href="#formation"> <FontAwesomeIcon icon={faGraduationCap} /></a>
                                            </li>
                                            <li>
                                                <Link to="/contact"> <FontAwesomeIcon icon={faEnvelope} /></Link>
                                            </li>
                                            <li className="nav-right">
                                                <a href="https://www.instagram.com/kydo.7/"
                                                    target="_blank" rel="noreferrer"><FontAwesomeIcon
                                                        icon={faInstagram} /></a>
                                                <a href="https://www.linkedin.com/in/cody-macedo-dev/"
                                                    target="_blank" rel="noreferrer"><FontAwesomeIcon
                                                        icon={faLinkedin} /></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menu-responsive">
                                        <div className={isOpen ? "mobile-menu open" : "mobile-menu"}>
                                            <div className='mobile-menu-container'>
                                                <div className='mobile-menu-li'>
                                                    <a href="#resume" onClick={() => setIsOpen(!isOpen)}>
                                                        <FontAwesomeIcon icon={faUserAlt} /> Accueil
                                                    </a>
                                                </div>
                                                <div className='mobile-menu-li'><a href="#skill" onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon
                                                    icon={faBrain} /> Skills</a>
                                                </div>
                                                <div className='mobile-menu-li'><a href="#experience"
                                                    onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon
                                                        icon={faBriefcase} /> Experience</a>
                                                </div>
                                                <div className='mobile-menu-li'><a href="#formation" onClick={() => setIsOpen(!isOpen)}>
                                                    <FontAwesomeIcon
                                                        icon={faGraduationCap} /> Formation</a>
                                                </div>
                                                <div className='mobile-menu-li'><Link to="/contact" onClick={() => setIsOpen(!isOpen)}>
                                                    <FontAwesomeIcon
                                                        icon={faEnvelope} /> Contact</Link>
                                                </div>
                                            </div>

                                            <div className='mobile-menu-socialnetwork '>
                                                <a href="https://www.instagram.com/kydo.7/" target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faInstagram} size='xl' />
                                                </a>
                                                <a href="https://www.linkedin.com/in/cody-macedo-dev/" target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faLinkedin} size='xl' />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-btn">
                                        <div className={isOpen ? "menu-btn open" : "menu-btn"}
                                            onClick={() => setIsOpen(!isOpen)}>
                                            <span className="line" />
                                            <span className="line" />
                                            <span className="line" />
                                        </div>
                                    </div>
                                </div>

                            </nav>
                        </Tween>
                    )}
                </Scene>
            </Controller>
        </header>

    );
}

export default Header;