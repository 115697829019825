import React from 'react';

const SkillCard = ({img, title, text}) => {
    return (
        <div className="skill-card">
            <div className="skill-img">
                <img src={img} alt={"IMG" + title}/>
            </div>
            <div className="skill-text">
                <p> {title} </p>
                <p> XP// <span className="exp-year">{text} ans</span></p>
            </div>
        </div>
    );
};

export default SkillCard;
