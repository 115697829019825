import Experience from "./Experience/Experience";
import Skills from "./Skills/Skills";
import Resume from "./Resume";
import Education from "./Education";
import ScrollToTop from "./ScrollToTop";

const Home = () => {
    return (
        <div>
            <ScrollToTop>
                <Resume />
            </ScrollToTop>
            <Skills />
            <Experience />
            <Education />
        </div>
    );
}

export default Home;
